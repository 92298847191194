body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'WorkSans';
  src: local('WorkSans'), url(./fonts/WorkSans/WorkSans-VariableFont_wght.ttf) format('truetype');
}

:root {
  --color-eggshell: rgb(243, 239, 227);
  --color-sand: rgb(219, 208, 187);
  --color-sage: rgb(166, 167, 151);
  --color-dark-gray: rgb(37, 50, 51);
  --color-camel: rgb(156, 125, 81);

  --button-hover-color: rgb(233, 233, 254);
}