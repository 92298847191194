.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-width: 100vw;
}

.app-header {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  z-index: 1;
  background-image: linear-gradient(var(--color-eggshell), orange);
  max-width: 100vw;
}

.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.app-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  min-height: 100%;
  background-color: var(--color-eggshell);
}

.logo-button {
  background-color: transparent;
  border: none;
}

.logo-img {
  max-width: 250px;
}

.logo-text {
  font-size: small;
}

.page-title {
  color: var(--color-dark-gray);
  font-size: 2rem;
}

/* GENERIC CSS */

/* separators */
.vertical-separator-xs {
  min-width: 5px;
}

.vertical-separator-s {
  min-width: 10px;
}

.vertical-separator-m {
  min-width: 25px;
}

.vertical-separator-l {
  min-width: 50px;
}

.horizontal-separator-xs {
  min-height: 5px;
}

.horizontal-separator-s {
  min-height: 10px;
}

.horizontal-separator-m {
  min-height: 25px;
}

.horizontal-separator-l {
  min-height: 50px;
}

/* lists */
li {
  text-align: left;
}

/* box */
.box {
  margin: 10px;
  border-style: solid;
  border-radius: 2px;
}

/* buttons */
button {
  font-family: 'WorkSans';
}

h1 {
  font-family: 'Ogg';
}

h2 {
  font-family: 'Ogg';
}

h3 {
  font-family: 'Ogg';
}

h4 {
  font-family: 'Ogg';
}

.app-button {
  background-color: transparent;
  display: inline-block;
  line-height: 39px;
  padding: 0 23px;
  border: 2px solid black;
  font-size: 1rem;
  font-weight: 700;
  font-family: inherit;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  transition: color .1s linear, background-color .1s linear, border-color .1s linear;
}

.app-button:hover {
  color: white;
  border: 2px solid var(--color-sand);
  background-color: var(--color-sand);
  cursor: pointer;
}

p {
  color: var(--color-dark-gray);
}

.app-creds-form {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  margin-right: 30px;
  max-width: 70vw;
}

.form-button {
  align-self: center;
}

@media screen and (max-width: 480px) {
  .logo-img {
    max-width: 160px;
  }

  .page-title {
    font-size: 1.2rem;
  }
}